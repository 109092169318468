import React from 'react';
import Scroll from '../Scroll';

export default function Top({ title, content }) {
  return (
    <section id='top' className='one dark cover'>
      <div className='container'>
        <header>
          <h2 className='alt'>
            { title }
          </h2>
          <main>
            <p dangerouslySetInnerHTML={{__html: content}} >
            </p>
          </main>
        </header>

        <footer>
        </footer>
      </div>
    </section>
  );
}
