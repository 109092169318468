import React from 'react';
import Layout from '../../components/Layout';
import SideBar from '../../components/SideBar';
import Top from '../../components/Top/Top';
import {Data} from '../../data/data.en';
import vietnameFlag from '../../assets/images/vietnam.png'

const IndexPage = () => (
  <Layout>
    <SideBar sections={Data.sidebar} />

    <div id='main'>
      <div className='lang'>
        <a href='/'>
          <img src={vietnameFlag} alt='VietNam' />
        </a>
      </div>

      <Top title={Data.top.title} content={Data.top.content} />
    </div>

  </Layout>
);

export const Head = () => {
    return (
        <>
            <meta http-equiv = "content-language" content = "en" />
        </>
    );
};

export default IndexPage;
